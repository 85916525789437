/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import ReferralWrapper from "./src/ReferralWrapper"

import "./src/styles/global.css"
import "./src/styles/wpbr-public-main.css"
import "./src/styles/custome.css"
import "./src/styles/front-page.css"
import "./src/styles/responsive.css"

export const wrapRootElement = ReferralWrapper
